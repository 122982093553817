<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Налаштування</v-subheader>
              <v-row class="dense-wrapper">
                <v-col cols="12">
                  <ACC_Position :value="filters.position_id" @autocompleteChange="onCityChange"/>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete hide-details filled label="Підрозділ"
                                  multiple
                                  v-model="filters.subdivisions"
                                  :items="subdivision_items"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete hide-details filled label="Працівники"
                                  multiple
                                  v-model="filters.persons"
                                  :items="personal_items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="pages" id="print">
      <div class="page page-a4 page-separator page-for-print" v-for="(page, index) in pages" :key="`page-${index}`" style="margin-bottom: 2px;">
        <div class="report-section" style="flex-wrap: wrap">
          <div class="rp-col-12 receipt" v-for="(col, idx) in items.filter(item => item.page === page)" :key="`col-idx-${idx}`" >
            <div class="receipt-content" style="font-size: 11px; padding-top: 2px">
              <div class="receipt-row" style="font-weight: 500; align-items: center">
                <div style="flex: 1; font-size: 16px; text-align: center">
                  {{ `Розрахунковий листок за ${formatDate(date_start, 'MMMM YYYY')}` }}
                </div>
              </div>
              <div class="empty-row" style="height: 3px"></div>
              <div class="receipt-row" style="max-height: 32px; overflow: hidden; line-height: 1.1; padding-bottom: 4px">
                <div style="flex: 1; font-size: 16px; font-weight: bold">
                  {{ col.full_name }}
                </div>
                <div style="flex: 0 0 100px; font-size: 16px">
                  До виплати:
                </div>
                <div style="flex: 0 0 100px; font-size: 16px; text-align: right; font-weight: bold">
                  {{ col.to_pay | formatToFixed | formatNumber }}
                </div>
              </div>
              <div class="empty-row" style="height: 2px; border-top: 2px solid; padding-bottom: 4px"></div>
              <div class="receipt-row">
                <div style="flex: 1 55%">
                  <div class="d-flex">
                    <div style="flex: 0 0 140px" class="top-section-title">
                      Табельний номер:
                    </div>
                    <div style="flex: 1" class="top-section-data">
                      {{ col.tab_number }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1 45%">
                  <div class="d-flex">
                    <div style="flex: 0 0 100px" class="top-section-title">
                      Організація:
                    </div>
                    <div style="flex: 1" class="top-section-data">
                      {{print_data.organization_short_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 1 55%">
                  <div class="d-flex">
                    <div style="flex: 0 0 140px" class="top-section-title">
                      Код за ДРФО:
                    </div>
                    <div style="flex: 1" class="top-section-data">
                      {{ col.ipn }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1 45%">
                  <div class="d-flex">
                    <div style="flex: 0 0 100px" class="top-section-title">
                      Оклад (тариф):
                    </div>
                    <div style="flex: 1" class="top-section-data">
                      {{ col.main_sum | formatToFixed | formatNumber }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 1 55%">
                  <div class="d-flex">
                    <div style="flex: 0 0 140px" class="top-section-title">
                      Підрозділ:
                    </div>
                    <div style="flex: 1" class="top-section-data">
                      {{ col.subdivision_name }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1 45%">

                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 1 55%">
                  <div class="d-flex">
                    <div style="flex: 0 0 140px" class="top-section-title">
                      Посада:
                    </div>
                    <div style="flex: 1" class="top-section-data">
                      {{ col.position_name }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1 45%">

                </div>
              </div>
              <div class="empty-row" style="height: 4px"></div>

              <div class="receipt-row">
                <div class="report-table">
                  <div class="report-table-row">
                    <div class="report-table-col" style="flex: 0 0 170px">
                      <span>Вид</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 100px">
                      <span>Період</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 45px">
                      <span>Дні</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 45px">
                      <span>Години</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 90px;">
                      <span>Сума</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 223px;">
                      <span>Вид</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 90px;">
                      <span>Сума</span>
                    </div>
                  </div>
                  <div class="report-table-row" v-for="(row, row_idx) in col.items" :key="`page-${index}-${row_idx}`">
                    <template v-if="row.accrual_name === '1. Нарахування'">
                      <div class="report-table-col" style="flex: 0 0 450px">
                        <span style="font-weight: bold">{{ row.accrual_name }}</span>
                      </div>
                    </template>
                    <template v-else-if="row.accrual_name === 'Всього нараховано'">
                      <div class="report-table-col" style="flex: 0 0 360px">
                        <span
                            class="text-wrap subtitle"
                            style="line-height: 1.1 !important; text-align: right; width: auto !important;"
                        >{{ row.accrual_name }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 90px">
                        <span>{{ row.charge_sum | formatToFixed | formatNumber }}</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="report-table-col" style="flex: 0 0 170px">
                        <span
                            class="text-wrap" style="line-height: 1.1 !important;"
                        >{{ row.accrual_name }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 100px">
                        <span>{{ row.month | formatDate('MMMM YYYY') }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 45px">
                        <span>{{ row.days }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 45px">
                        <span>{{ row.hours }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 90px">
                        <template v-if="row.is_charge && row.accrual_name">
                          <span>{{ row.charge_sum | formatToFixed | formatNumber }}</span>
                        </template>
                      </div>
                    </template>
                    <template v-if="row.is_tax">
                      <template v-if="row.tax_name === '2. Утримання із зарплати'">
                        <div class="report-table-col" style="flex: 0 0 313px;">
                          <span style="justify-content: left; font-weight: bold">{{ row.tax_name }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="report-table-col" style="flex: 0 0 223px">
                          <span
                              style="justify-content: left"
                              :class="{subtitle: row.tax_name === 'Всього утримано'}"
                          >{{ row.tax_name }}</span>
                        </div>
                        <div class="report-table-col" style="flex: 0 0 90px;">
                          <span>{{ row.tax_sum | formatToFixed | formatNumber }}</span>
                        </div>
                      </template>
                    </template>
                    <template v-if="row.is_pay">
                      <template v-if="row.pay_document === '3. Виплата заробітної плати'">
                        <div class="report-table-col" style="flex: 0 0 313px">
                          <span style="justify-content: left; font-weight: bold">{{ row.pay_document }}</span>
                        </div>
                      </template>
                      <template v-else-if="row.pay_document === 'Всього оплачено'">
                        <div class="report-table-col" style="flex: 0 0 223px">
                          <span
                              style="justify-content: left; text-align: left" class="subtitle"
                          >{{ row.pay_document }}</span>
                        </div>
                        <div class="report-table-col" style="flex: 0 0 90px;">
                          <span>{{ row.pay_sum | formatToFixed | formatNumber }}</span>
                        </div>
                      </template>
                      <template v-else-if="row.pay_document">
                        <div class="report-table-col" style="flex: 0 0 223px">
                          <span
                              style="justify-content: left; text-align: left"
                          >{{ `${row.pay_operation} від ${formatDate(row.pay_date)}` }}</span>
                        </div>
                        <div class="report-table-col" style="flex: 0 0 90px;">
                          <span>{{ row.pay_sum | formatToFixed | formatNumber }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="report-table-col" style="flex: 0 0 223px">
                          <span
                              style="justify-content: left; text-align: left"
                          ></span>
                        </div>
                        <div class="report-table-col" style="flex: 0 0 90px;">
                          <span></span>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div class="report-table-row">
                    <div class="report-table-col" style="flex: 0 0 360px">
                      <template v-if="col.balance_start < 0">
                        <span style="width: auto !important; font-weight: bold">
                          {{ `4. Борг на ${formatDate(date_start)} року перед підприємством` }}
                        </span>
                      </template>
                      <template v-if="col.balance_start >= 0">
                        <span style="width: auto !important; font-weight: bold">
                          {{ `4. Борг на ${formatDate(date_start)} року перед працівником` }}
                        </span>
                      </template>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 90px">
                      <template v-if="col.balance_start < 0">
                        <span style="width: auto !important; font-weight: bold">
                          {{ -col.balance_start }}
                        </span>
                      </template>
                      <template v-if="col.balance_start >= 0">
                        <span style="width: auto !important; font-weight: bold">
                          {{ col.balance_start }}
                        </span>
                      </template>
                    </div>

                    <div class="report-table-col" style="flex: 0 0 223px">
                      <template v-if="col.balance_end < 0">
                        <span style="width: auto !important; font-weight: bold; text-align: left; justify-content: left !important;">
                          {{ `5. Борг на ${formatDate(date_end)} року перед підприємством` }}
                        </span>
                      </template>
                      <template v-if="col.balance_end >= 0">
                        <span style="width: auto !important; font-weight: bold; text-align: left; justify-content: left !important;">
                          {{ `5. Борг на ${formatDate(date_end)} року перед працівником` }}
                        </span>
                      </template>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 90px">
                      <template v-if="col.balance_end < 0">
                        <span style="width: auto !important; font-weight: bold;">
                          {{ -col.balance_end }}
                        </span>
                      </template>
                      <template v-if="col.balance_end >= 0">
                        <span style="width: auto !important; font-weight: bold;">
                          {{ col.balance_end }}
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <div class="empty-row" style="height: 12px"></div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports";
import accountinReportAPI from "@/utils/axios/accounting/reports"
import subdivisionAPI from "@/utils/axios/accounting/subdivision"
import personalAPI from "@/utils/axios/accounting/personal"
import {mapActions, mapGetters} from "vuex";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import {ALERT_SHOW} from "@/store/actions/alert";
// import {addMothsToStringDate, addDaysToStringDate, beginOfMonth} from "@/utils/icons"

export default {
  name: "SalaryAccountList",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'clear', 'generate_xlsx', 'flat'],
  components: {
    ACC_Position: () => import('@/components/autocomplite/AC_Position'),
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll'
        }
    ),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    without_flat() {
      return !this.flat || Object.keys(this.flat || {}).length === 0
    }
  },
  data() {
    return {
      pages: 0,
      per_page: 0,
      items: [],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      clear_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
      subdivision_items: [],
      personal_items: [],
      filters: {
        subdivisions: [],
        position_id: null,
        persons: []
      },
    }
  },
  methods: {
    ...mapActions(
        {
          fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE
        }
    ),
    getBasePath() {
      return window.location.origin
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    onCityChange(payload) {
      this.filters.position_id = payload.value || null
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_square: this.filters.show_square,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            custom_title: this.filters.custom_title,
            title: this.filters.title,
            additional_text: this.filters.additional_text
          }
        }
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }
        payload.filters.user_url = this.getBasePath()

        reportAPI.receipt_detail_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `receipt_detail_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              this.$emit('xlsx_loading_status', false)
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters)
        }
        accountinReportAPI.salary_accounting_list(payload)
            .then(response => response.data)
            .then(data => {
              this.setSplitter(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.expanded = []
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
            })
      }
    },
    setSplitter(payload) {
      let pages = 0
      let per_page = 860
      let counter = 0
      let by_receipt = 190
      let max_pages = 0

      payload.forEach(item => {
        counter += by_receipt

        item.items.forEach(i => {
          const accrual_l = (i.accrual_name || '').length
          const tax_l = (i.tax_name || '').length
          let pay_l = 0

          if (i.is_pay) {
            if (i.pay_document === '3. Виплата заробітної плати') {
              pay_l = i.pay_document.length
            } else if (i.pay_document === 'Всього оплачено') {
              pay_l = i.pay_document.length
            } else {
              if (i.pay_document) {
                pay_l = `${i.pay_operation} від ${formatDate(i.pay_date)}`
              }
            }
          }

          const max_length = Math.max(accrual_l, tax_l, pay_l)

          counter += max_length > 27 ? 34.88 : 17.44

          if (counter >= per_page) {
            item['page'] = pages + 1
            pages += 1
            counter = 0
            counter += by_receipt
          } else {
            item['page'] = pages + 1
          }

          if (item['page'] > max_pages) {
            max_pages = item['page']
          }
        })
      })

      if (payload.length > 0 && pages === 0) {
        pages = 1
      }

      if (max_pages > pages) {
        pages = max_pages
      }

      this.pages = pages
      this.per_page = per_page
      this.items = Object.freeze(payload)
    },
    formatDate,
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.generate_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_clear() {
      this.clear_watcher = this.$watch(
          'clear',
          {
            handler(payload) {
              if (payload) {
                this.items = []
                this.pages = 0
                this.per_page = 0
                this.$emit('afterClear')
              }
            }
          }
      )
    },
    getContacts() {
      let contacts = ""
      if (this.print_data || this.print_data.contacts) {
        this.print_data.contacts.forEach(item => {
          contacts += `${item.name} ${item.value}  `
        })
      }
      return contacts
    }
  },
  created() {
    personalAPI.select()
        .then(response => response.data)
        .then(data => this.personal_items = data)
    subdivisionAPI.select()
        .then(response => response.data)
        .then(data => this.subdivision_items = data)

    this.watch_settings()
    this.watch_generate()
    this.watch_clear()
    this.fetchServices()
    this.watch_generate_xlsx()

    // if (this.without_flat) {
    reportAPI.report_settings("salary_account_list")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              g.on = !!server_filters.grouping.includes(g.value);
            })
          }
        })
    // }
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.clear_watcher) {
      this.clear_watcher()
    }
    if (this.generate_xlsx_watcher) (
        this.generate_xlsx_watcher()
    )
  }
}
</script>

<style scoped lang="scss">
$border-color: #cdcdcd;

@media print {
  .page {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .report-table-group-header {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .report-table-group-content {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  //@page {
  //  size: A4 portrait;
  //}

  .page-separator {
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: avoid;
  }
  //.separator {
  //  page-break-before: right;
  //  //break-before: page;
  //}
}

.page {
  .receipt {
    &:nth-child(1) {
      border-right: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(2) {
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(3) {
      border-right: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(4) {
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(5) {
      border-right: 1px dotted $border-color;
    }
  }
}



.page {
  .report-section {
    .receipt {
      border: none !important;
      &:first-child {
        margin-top: 0;
      }

      margin-top: 12px;
    }
  }
}

.page-separator {
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.receipt {
  //height: 95mm;
  border: none !important;

  //&:nth-child(even) {
  //  border-left: 1px dotted grey;
  //  border-bottom: 1px dotted grey;
  //}

  .receipt-row {
    display: flex;
  }
}

.receipt-content {
  overflow: hidden;
}

.report-table {
  width: 100%;
  text-align: center;

  .report-table-row:nth-child(1) {
    & > div {
      background-color: rgb(249 249 249 / 74%) !important;
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      font-weight: 500;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

  .report-table-row:last-child {
    & > div {
      border-bottom: 1px solid $border-color;

      //&:nth-child(1) {
      //  border-left: 1px solid grey;
      //}
    }
  }

  .report-table-row {
    min-height: 16px;
    line-height: 1.12;
    font-size: 12px;

    & > div {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

}

.report-table-col {
  &:nth-child(1) {
    span {
      line-height: 0;
      justify-content: flex-start;
      white-space: nowrap;
      text-align: left;
      width: 168px;
      overflow: hidden;
    }
  }

  span {
    padding: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.report-table-group {
  font-size: 12px;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $border-color !important;
  }

  .report-table-group-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;
    border-top: 1px solid $border-color;
    font-weight: 500;
    background-color: #f2f2f2;

    .report-table-col {
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }

  }

  .report-table-group-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;

    .report-table-col {
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;

      &:nth-child(1) {
        padding-left: 30px !important;
        padding-right: 5px;
        border-left: 1px solid $border-color;
      }
    }
  }
}

.col-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .col-group-row {
    display: flex;

    &:nth-child(1) {
      border-bottom: 1px solid $border-color;
      text-align: center;
      justify-content: center;
    }

    .report-table-col {
      border-right: 1px solid $border-color;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.top-section-title {
  font-size: 13px;
}
.top-section-data {
  font-size: 13px;
}
.subtitle {
  font-size: 12px;
  font-weight: 600;
  justify-content: right !important;
  padding-right: 6px !important;
}

</style>